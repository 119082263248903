import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tactic',
  templateUrl: './tactic.component.html',
  styleUrls: ['./tactic.component.scss'],
})
export class TacticComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
