import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Weltkrieg', url: '/war', icon: 'rocket' },
    // { title: 'Artefakte', url: '/artifacts', icon: 'trophy' },
    // { title: 'Taktik', url: '/tactic', icon: 'library' },
    { title: 'Basisbau', url: '/bases', icon: 'construct' },
    { title: 'Beispielbasen', url: '/examples', icon: 'construct' },
    { title: 'Signal', url: '/signal', icon: 'chatbubble-ellipses' },
  ];
  public leaders = ['Chee Z', 'Yellowbart', 'Pappe'];
  constructor() {}
}
