import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-world-war',
  templateUrl: './world-war.component.html',
  styleUrls: ['./world-war.component.scss'],
})
export class WorldWarComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
