import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-example-bases',
  templateUrl: './example-bases.component.html',
  styleUrls: ['./example-bases.component.scss'],
})
export class ExampleBasesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
